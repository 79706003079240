import { useContext, useEffect, useState } from 'react';
import {
    getPhotoSceneUrl,
    getResizeByDimensionsUrl,
    getResizeDocumentWithSurfaceJson,
    getResizeDocumentWithSurfaceUrl,
} from '../../api';
import { ApiKeyContext } from '../../App';
import { getUrl } from '../../tools';
import { type Card, CardBuilder, PreviewRow, PreviewRows, PreviewSection } from '../common';
import type { Physicality, SurfaceData } from './types';

interface ResizePreviewProps {
    documentUrl: string;
    physicalities?: Physicality[];
    surfacesData: SurfaceData[];
    customPhysicality?: Physicality;
    projection?: string;
    debug: boolean;
    minimumFontSize?: string;
    intro: string;
    surfaceSpecification?: any;
}

export const ResizePreview = ({
    documentUrl,
    physicalities,
    surfacesData,
    customPhysicality,
    projection,
    debug,
    minimumFontSize,
    intro,
    surfaceSpecification,
}: ResizePreviewProps) => {
    const [sourceRow, setSourceRow] = useState<Card[]>([]);
    const [resizeForSurfaceCards, setResizeForSurfaceCards] = useState<Card[]>([]);
    const [physicalityCards, setPhysicalityCards] = useState<Card[]>([]);
    const [customPhysicalityCards, setCustomPhysicalityCards] = useState<Card[]>([]);
    const apiKey = useContext(ApiKeyContext);

    useEffect(() => {
        const sourceCard = CardBuilder.ofType('documentUrl')
            .withTitle('Source preview')
            .withData({ documentUrl })
            .build();

        setSourceRow(PreviewRow(sourceCard));
    }, [documentUrl]);

    useEffect(() => {
        const uri = getUrl(documentUrl);

        if (!uri || (!surfaceSpecification && (!surfacesData?.length || !surfacesData[0].surfaceUrl))) {
            setResizeForSurfaceCards([]);
            return;
        }

        if (surfaceSpecification) {
            const physicalityUrl = getResizeDocumentWithSurfaceJson(
                uri,
                surfaceSpecification,
                projection,
                minimumFontSize,
                apiKey,
            );
            const newSurfaceSpecificationCard = CardBuilder.ofType('documentUrl')
                .withTitle('Result Document')
                .withData({ documentUrl: physicalityUrl ?? '', backgroundColor: 'FFFFFF' })
                .build();

            setResizeForSurfaceCards([newSurfaceSpecificationCard]);
        } else if (surfacesData?.length && surfacesData[0].surfaceUrl) {
            const newResizeForSurfaceCards: Card[] = surfacesData.map((s) => {
                const name = s.title;
                const resizeUrl = getResizeDocumentWithSurfaceUrl(
                    uri,
                    s.surfaceUrl,
                    projection,
                    minimumFontSize,
                    apiKey,
                );

                return CardBuilder.ofType('documentUrl')
                    .withTitle(name)
                    .withLoader(async ({ signal }) => {
                        const photoSceneUrl = (await getPhotoSceneUrl(s.surfaceUrl, signal)) ?? '';

                        return {
                            documentUrl: resizeUrl,
                            photoSceneUrl,
                            surfaceUrl: s.surfaceUrl,
                            projection,
                            backgroundColor: 'FFFFFF',
                            debug,
                        };
                    })
                    .build();
            });

            setResizeForSurfaceCards(newResizeForSurfaceCards);
        }
    }, [
        documentUrl,
        minimumFontSize,
        surfaceSpecification,
        surfacesData,
        projection,
        debug,
        apiKey,
        setResizeForSurfaceCards,
    ]);

    useEffect(() => {
        const uri = getUrl(documentUrl);
        if (!uri || !customPhysicality) {
            setCustomPhysicalityCards([]);
            return;
        }

        const customPhysicalityUrl = getResizeByDimensionsUrl(
            uri,
            customPhysicality.width,
            customPhysicality.height,
            minimumFontSize,
            apiKey,
        );

        const newCustomPhysicalityCard = CardBuilder.ofType('documentUrl')
            .withTitle('Custom')
            .withData({ documentUrl: customPhysicalityUrl ?? '', backgroundColor: 'FFFFFF' })
            .build();

        setCustomPhysicalityCards([newCustomPhysicalityCard]);
    }, [documentUrl, customPhysicality, minimumFontSize, setCustomPhysicalityCards, apiKey]);

    useEffect(() => {
        const uri = getUrl(documentUrl);
        if (!uri || !physicalities?.length) {
            setPhysicalityCards([]);
            return;
        }

        const newPhysicalityCards: Card[] = physicalities.map((p) => {
            const physicalityUrl = getResizeByDimensionsUrl(uri, p.width, p.height, minimumFontSize, apiKey);
            return CardBuilder.ofType('documentUrl')
                .withTitle(p.name)
                .withData({ documentUrl: physicalityUrl ?? '', backgroundColor: 'FFFFFF' })
                .build();
        });

        setPhysicalityCards(newPhysicalityCards);
    }, [documentUrl, physicalities, minimumFontSize, setPhysicalityCards, apiKey]);

    const resultRow = PreviewRow(...resizeForSurfaceCards, ...customPhysicalityCards, ...physicalityCards);

    return <PreviewSection introduction={intro} showIntro={!documentUrl} rows={PreviewRows(sourceRow, resultRow)} />;
};
